import {
  baseApiUrlDev,
  baseApiUrlProd,
  baseApiUrlStaging,
  baseApiUrlTest,
  baseApiUrlApp,
} from 'legacy/shared/v1/constants/api';

export const getEnv = () => {
  const host = window && window.location.host;
  return host.split('.')[0];
};

const apiUrl = (() => {
  const host = window && window.location.host;

  // THIS IS SUBBED OUT DURING THE BUILD PROCESS
  //
  return 'https://api.huckfinn.firstresponder.ai';

})();
export default apiUrl;
